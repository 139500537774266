.text-generator__char {
  display: inline-block;
  opacity: 0;
  animation: text-generator-animation 1s ease forwards;
}

@keyframes text-generator-animation {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.stat-box {
  height: 350px;
  width: 100%;
  padding: 10px;
}
.stat-box__groups {
  width: 100%;
  height: 370px;
  padding: 10px;
}

.stat-box-nonedata {
  height: calc(100vh - 559px);
  width: 100%;
  padding: 10px;
}
.stat-box-h500 {
  height: 500px;
  width: 100%;
  padding: 10px;
}
.stat-box-h600 {
  height: 600px;
  width: 100%;
  padding: 10px;
}

.typography {
  text-align: center;
  font-weight: 700;
  margin-bottom: 8px;
}

.h-90 {
  height: 100%;
}
.tooltip-item {
  display: flex;
  justify-content: space-between;
}

.tooltip-key {
  text-align: left;
  margin-right: 5px;
}

.tooltip-value {
  text-align: right;
}

body {
  overflow: hidden !important;
}
